import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import toriCooper_img_1 from '../../../images/toriCooper.jpg';
import toriCooper_img_1_mobile from '../../../images/toriCooper_mobile.jpg';
const fullName = "Tori Cooper";

const copyList = [

    {
        type: "image",
        content: {
            src: toriCooper_img_1,
            mobileSrc: toriCooper_img_1_mobile,
            alt: "Tori Cooper",
            caption: "Tori Cooper"
        }
    },
    {
        type: "text",
        content: "<h3 class=\"title\">A conversation with Tori Cooper, Director of Community Engagement at the Human Rights Campaign and member of the Presidential Advisory Council on HIV/AIDS"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Trailblazer"
    // },

    {
        type: 'text',
        content: "Bold, fearless, pioneering—all words used to describe HIV activist and advocate Tori Cooper. But ask her, and she’d add “capable” to that list. The first Black trans person appointed to the Presidential Advisory Council on HIV/AIDS in 2021, she has a legacy of making a way out of no way, for herself and countless others in the Black, trans, and HIV communities. “I never saw anything as impossible. If there was ever a challenge, I always felt I could do it. I just always thought of myself as capable.”"
    },
    {
        type: "blockquote",
        content: "“I never saw anything as impossible. If there was ever a challenge, I always felt I could do it. I just always thought of myself as capable.”",
        author: fullName
    },
    {
        type: 'text',
        content: "An only child assigned male at birth, “I didn’t see people who I thought were similar to how I saw myself.” That is, until her teens, when she discovered the pulsating music and ‘All are welcome here’ atmosphere of queer nightclubs. It’s there that Cooper expanded her innate sense of self and understanding of her womanhood.  But in that same love-filled environment, she’d soon bear witness to unspeakable tragedy. “AIDS decimated Black gay clubs,” she says of witnessing the devastation in the ‘80s. “There’s no other way to describe it. We’d be partying together, and a couple weeks later there’d be a funeral announcement. My very best friend died when I was 23.” Early on, when she took her first HIV test, Cooper received a sobering false-positive result. “I didn’t tell anybody,” she recalls, initially thinking she’d contracted the disease before a re-test proved otherwise. “I remember the shock and how alone I felt, especially at such a young age.”"
    },
    {
        type: "text",
        content: `It’s a feeling she’s never wanted anyone else to have, and why she’s dedicated
        her life to helping end HIV—especially in the community she loves. In the ‘90s, Cooper
        began a career in HIV advocacy, creating fliers and condom packets with
        the Gay Men’s Health Crisis* in New York. Later, she teamed with Zakia Jemaceye to help
        establish a program called Sistas Too, an adaptation of a Centers for Disease Control
        and Prevention (CDC) program to aid in HIV risk reduction in the trans community. Established
        in 1993, it was the first of its kind, a woman-to-woman intervention with a proven
        track record for arming patients with the knowledge and tools they needed to help protect themselves.`
    },
    {
        type: "text",
        content: `In the three decades since, Cooper, who’s also a cancer survivor, has done
        it all. From grassroots organizing to high-ranking posts—like her current role as
        Director of Community Engagement at the Human Rights Campaign and on President Biden’s HIV/AIDS
        advisory council—she’s proud to pass on the lessons she’s gleaned from
        years of service. “My presence as a Black trans woman has not only opened doors for
        others like me, but provided a visual that may encourage someone to know that they are valued
        and can do anything,” says Cooper. “If you want to live healthily, be in love, be
        happy, you can. I consider myself fortunate to be a part of a group of advocates doing
        amazing work to create change; not just around what people living with
        HIV think, but what the rest of the world thinks.”`
    },
    {
      type: "disclaimer",
    },
    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">TORI COOPER"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">Human Rights Campaign"
    // },
    {
        type: "championsofchangelandinglink",
          content: "<a class=\"championsofchangelandinglink\" href=\"/championsofchange\">Learn more about all our Champions of Change honorees "

    },

]

class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}

        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Tori Cooper, The Trailblazer"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}


      </div>
    );
  }
}

export default Content;
